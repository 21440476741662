/**
 *
 * @param {string} value
 * @param {*} defaultValue
 * @returns
 */
export const parseJSON = (value, defaultValue = null) => {
  if (!value) {
    return defaultValue;
  }

  try {
    return JSON.parse(value);
  } catch (e) {
    return defaultValue;
  }
};
