export const aggregateArrayOfObject = (array = [], objectKey = '', formattingFunction = null) => {
  const resultArray = [];
  array.forEach((obj) => {
    let match = false;
    resultArray.forEach((objResult) => {
      if (formattingFunction) {
        if (formattingFunction(obj[objectKey]) === formattingFunction(objResult[objectKey])) {
          match = true;
        }
      } else if (obj[objectKey] === objResult[objectKey]) {
        match = true;
      }
    });

    if (!match) {
      const newObj = {
        [objectKey]: obj[objectKey],
        values: [obj],
      };
      resultArray.push(newObj);
    } else {
      resultArray.forEach((objResult) => {
        if (formattingFunction) {
          if (formattingFunction(obj[objectKey]) === formattingFunction(objResult[objectKey])) {
            objResult.values.push(obj);
          }
        } else if (obj[objectKey] === objResult[objectKey]) {
          objResult.values.push(obj);
        }
      });
    }
  });

  return resultArray;
};

export const mergeArraysTicket = (arr1 = [], arr2 = []) => {
  let res = [];
  res = arr1.map((obj) => {
    const index = arr2.findIndex((el) => el.id === obj.id);
    const { amount } = index !== -1 ? arr2[index] : {};
    return {
      ...obj,
      amount,
    };
  });
  return res;
};

export const membershipPeriods = {
  1: '/ Bulan',
  3: '/ 3 Bulan',
  6: '/ 6 Bulan',
  12: '/ 12 Bulan',
};

