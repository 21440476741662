/* eslint-disable */

import Cookies from "js-cookie";
import {
  decrypt,
  defaultImage,
  encrypt,
  handleResizeImageUrl,
  parseJSON,
  updatePaginatedData,
} from ".";
// import { useQuery as useQueryURL } from '../hooks/useQuery';
import { useSearchParams } from "next/navigation";
import {
  IMAGE_LARGE_SIZE,
  IMAGE_MEDIUM_SIZE,
  IMAGE_SMALL_SIZE,
} from "@/lib/configs";

export const getBase64 = (file) => {
  if (typeof window !== "undefined") {
    return new Promise((resolve, reject) => {
      const reader = new window.FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.split(",")[1]);
      reader.onerror = (error) => reject(error);
    });
  }
};

export const uploadImageCallback = async (file, uploadImageMutation) => {
  const base64 = await getBase64(file);
  const image = { base64, fileType: file.type.split("/")[1], title: file.name };

  const res = await uploadImageMutation({ variables: image });

  return new Promise((resolve, reject) => {
    if (res.data.uploadCustomerImage.url) {
      const response = {
        data: {
          link: res.data.uploadCustomerImage.url,
          id: res.data.uploadCustomerImage.id,
        },
      };
      resolve(response);
    } else {
      reject("Error");
    }
  });
};

export const getWindowDimensions = () => {
  if (typeof window !== "undefined") {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }
};

export const isMobileScreen = () => {
  if (typeof window !== "undefined") {
    const { innerWidth: width } = window;

    if (width <= 500) return true;

    return false;
  }
};

export const updateFetchMore = (
  fetchMore = () => {},
  paramQuery = {},
  path = ""
) => {
  fetchMore({
    variables: paramQuery,
    updateQuery: (previousResult, { fetchMoreResult }) =>
      updatePaginatedData(path, previousResult, fetchMoreResult),
    notifyOnNetworkStatusChange: false,
  });
};

/**
 * Function to check if a product is part of saas flow
 * @param {String} type Product type
 * @param {String} membershipType Membership type
 * @returns {Boolean} True if its part of saas
 */
export const isSaasProduct = (type = "", membershipType = "") => {
  if (type === "saas" || (type === "membership" && membershipType === "SAAS"))
    return true;

  return false;
};

export const handleAffiliateRefferalId = () => {
  const paramReferralId = useSearchParams().get("ma");
  const cookiesReferralId = Cookies.get(`ma`);
  if (paramReferralId) {
    Cookies.set(`ma`, encrypt(paramReferralId), {
      expires: 30,
      domain:
        process.env.NEXT_PUBLIC_BASE_URL === "http://127.0.0.1:3001"
          ? "127.0.0.1"
          : process.env.NEXT_PUBLIC_BASE_URL,
    });
    return paramReferralId;
  }
  if (!paramReferralId && cookiesReferralId) {
    return decrypt(cookiesReferralId);
  }
  return null;
};

export const getCurrentVariant = (paymentLinkTransaction) => {
  let currVariant = null;

  if (
    paymentLinkTransaction.orderVariant &&
    paymentLinkTransaction.paymentLink &&
    paymentLinkTransaction.paymentLink.variant
  ) {
    const variants = parseJSON(paymentLinkTransaction.paymentLink.variant, []);
    const orderVariant = parseJSON(paymentLinkTransaction.orderVariant, []);
    currVariant = variants.find(
      (variant) => variant.id === orderVariant[0].variantId
    );
  }

  return currVariant;
};

export const isProductExpiredSoon = (expiredDate, days) => {
  const currentDate = new Date();

  return (
    new Date(expiredDate) <=
    currentDate.setDate(currentDate.getDate() + (days || 0))
  );
};

// Function to filter one image between `multipleImage`, `coverImage` and resizing the image through cdn
export const getCoverImage = (coverImage, multipleImage, resize) => {
  const resizeOption = {
    sm: IMAGE_SMALL_SIZE,
    md: IMAGE_MEDIUM_SIZE,
    lg: IMAGE_LARGE_SIZE,
  };

  const resizeLimit = resizeOption[resize] || resizeOption.lg;

  let res = "";

  if (multipleImage?.length) {
    res = [...multipleImage].sort((a, b) => a.updatedAt - b.updatedAt)[0];
  } else {
    res = coverImage;
  }

  if (res?.url) {
    // If image is from the database, then resize it
    res = handleResizeImageUrl(
      res.url,
      res.width > resizeLimit ? resizeLimit : res.width
    );
  } else {
    // Fallback image if multiple image and cover image does not exist
    res = defaultImage.url;
  }

  return res;
};
