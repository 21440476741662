import { sanitizeDOMIgnoreMedia } from './sanitizer';

export const copyToClipboard = (id) => {
  /* Get the text field */
  const copyText = document.getElementById(id);

  /* Select the text field */
  copyText.select();
  copyText.setSelectionRange(0, 99999); /*For mobile devices*/

  /* Copy the text inside the text field */
  document.execCommand('copy');
};

export const copyToClipboardByValue = (value = '') => {
  const textField = document.createElement('textarea');
  textField.innerText = value;
  document.body.appendChild(textField);
  textField.select();
  document.execCommand('copy');
  textField.remove();
};

export const isHTML = (str) => {
  const a = document.createElement('div');
  a.innerHTML = sanitizeDOMIgnoreMedia(str);

  for (let c = a.childNodes, i = c.length; i--; ) {
    if (c[i].nodeType === 1) return true;
  }

  return false;
};

export const convertSVGToDataUrl = (svgElement) => {
  if (typeof window !== "undefined") {
    const svgToProcess = svgElement || document.createElement('svg');
  
    // Serialize the svg to string
    const svgString = new window.XMLSerializer().serializeToString(svgToProcess);
  
    // Remove any characters outside the Latin1 range
    const decoded = unescape(encodeURIComponent(svgString));
  
    // Now we can use btoa to convert the svg to base64
    const base64 = window.btoa(decoded);
  
    return `data:image/svg+xml;base64,${base64}`;
  }
};
