import { defaultChannelConfig } from './configs';
import { parseJSON } from './parser';

export const checkPaymentConfig = (payChannelConfig, defaultConfig = defaultChannelConfig) => {
  const currentConfig = parseJSON(payChannelConfig, []);
  const resultConfig = defaultConfig.map((valConfig) => {
    const currentConfigPayment = currentConfig.find(
      (v) => v.code?.toUpperCase() === valConfig.code?.toUpperCase(),
    );

    if (currentConfigPayment) {
      valConfig.status = currentConfigPayment.status;
    }

    return valConfig;
  });

  return resultConfig;
};
