import { productWithContentViewer } from "./configs";

export const checkSubdomain = (uname) => {
  if (typeof window !== "undefined") {
    const hostname = window.location.hostname;
    if (hostname.includes("mayar.shop") || hostname.includes("myr.id")) {
      return `https://${uname}.${process.env.NEXT_PUBLIC_BASE_URL}`;
    }
  }
  return `https://${uname}`;
};

export const baseURL = (username) =>
  process.env.NODE_ENV === "development"
    ? `${process.env.NEXT_PUBLIC_BASE_URL}`
    : checkSubdomain(username);

export const rootUrl = (link) => {
  return process.env.NODE_ENV === "development"
    ? `${baseURL(link)}/${link}`
    : `${baseURL(link)}`;
};

export const subdomainName = () => {
  if (typeof window !== "undefined") {
    // Client-side-only code
    return window.location.hostname.includes("mayar.shop") ||
      window.location.hostname.includes("myr.id")
      ? window.location.hostname.split(".")[0]
      : window.location.hostname;
  }
};

export const createInternalPath = (path, paymeLink) => {
  return process.env.NODE_ENV === "development" ? `/${paymeLink}${path}` : path;
};

export const paymeUrl = (link) => {
  return `${baseURL(subdomainName)}/payme/${link}`;
};

export const paymentLinkUrl = (link) => {
  return `${baseURL(subdomainName)}/pl/${link}`;
};

export const donateLinkUrl = (link) => {
  return `${baseURL(subdomainName)}/donate/${link}`;
};

export const webinarPageUrl = (username, id) => {
  return `${baseURL(subdomainName)}/${username}/webinar/${id}`;
};

export const fundraisingPageUrl = (username, id) => {
  return `${baseURL(subdomainName)}/galang-dana/${id}`;
};

export const waqafPageUrl = (username, id) => {
  return `${baseURL(subdomainName)}/${username}/wakaf/${id}`;
};

export const catalogPageUrl = (username, id) => {
  return `${baseURL(subdomainName)}/${username}/catalog/${id}`;
};

export const subscriptionUrl = (link) => {
  return `${baseURL(subdomainName)}/s/${link}`;
};

export const membershipLinkStaticUrl = () => {
  return `${baseURL(subdomainName)}/pay-membership`;
};

export const productPageDetail = (username, link, type, subType = "") => {
  let pathType = "pl";

  switch (type) {
    case "digital_product":
      pathType = "catalog";
      break;
    case "generic_link":
      pathType = "plink";
      break;
    case "physical_product":
      pathType = "product-catalog";
      break;
    case "webinar":
      pathType = "webinar";
      break;
    case "event":
      pathType = "event";
      break;
    case "coaching":
      pathType = "coaching";
      break;
    case "course":
      pathType = "course";
      break;
    case "cohort_based":
      pathType = "bootcamp";
      break;
    case "membership":
      pathType = "membership";
      break;
    case "audiobook":
      pathType = "audiobook";
      break;
    case "ebook":
      pathType = "ebook";
      break;
    case "podcast":
      pathType = "podcast";
      break;
    case "fundraising":
      switch (subType) {
        case "FUNDRAISING_WAQAF":
          pathType = "wakaf";
          break;
        case "FUNDRAISING_QURBAN":
          pathType = "qurban";
          break;

        default:
          pathType = "galang-dana";
          break;
      }
      break;
    case "bundling":
      pathType = "bundling";
      break;
    case "saas":
      pathType = "app";
      break;
    case "writing":
      pathType = "writing";
      break;
    default:
      pathType = "pl";
      break;
  }

  return `/${process.env.NEXT_PUBLIC_MAYAR_ENV_EXACT === "local" ? `${username}/` : ''}${pathType}/${link}`;
};

export const isSignPage = (check) => {
  if (typeof window !== "undefined" && !check) {
    check = window.location.hash
      ? window.location.hash.replace(/^#/g, "")
      : window.location.pathname;
  }

  return check === "/sign-in" || check === "/sign-up";
};

export const isPublicPage = (check) => {
  if (typeof window !== "undefined" && !check) {
    check = window.location.hash
      ? window.location.hash.replace(/^#/g, "")
      : window.location.pathname;
  }

  return (
    check.match(/pl\/.*/) ||
    check.match(/payme\/.*/) ||
    check.match(/donate\/.*/) ||
    check.match(/plt\/.*/) ||
    check.match(/s\/.*/) ||
    check.match(/pay-thank-you\/.*/) ||
    check === "/pay-channel-qris" ||
    check === "/pay-me-select-channel" ||
    check === "/pay-me-channel-qris" ||
    check === "/pay-channel-va" ||
    check === "/pay-failed" ||
    check === "/pay-channel-retail" ||
    check === "/pay-credit-card" ||
    check === "/pay-channel-ovo" ||
    //customer portal
    check.match(/.*\/portal\/.*/) ||
    check.match(/portal\/dashboard/) ||
    check.match(/portal\/recurring-detail\/.*/) ||
    check.match(/portal\/invoice-detail\/.*/) ||
    check.match(/portal\/download\/.*/) ||
    check.match(/portal\/course-content\/.*/) ||
    check.match(/portal\/order-detail\/.*/) ||
    check.match(/portal\/webinar-detail\/.*/) ||
    check.match(/portal\/event-detail\/.*/) ||
    //invoice
    check.match(/invoice\/.*/) ||
    //digital product
    check === "/pay-digital-product" ||
    check.match(/.*\/catalog/) ||
    check.match(/.*\/catalog\/.*/) ||
    //fundraising
    check.match(/.*\/galang-dana/) ||
    check.match(/.*\/galang-dana\/embed/) ||
    check.match(/.*\/galang-dana\/.*/) ||
    //webinar
    check.match(/.*\/webinar\/.*/)
  );
};

export const isCourseDashboard = (check) => {
  if (typeof window !== "undefined" && !check) {
    check = window.location.hash
      ? window.location.hash.replace(/^#/g, "")
      : window.location.pathname;
  }

  return (
    check.match(/.*\/course-content/) || check.match(/.*\/course-content\/.*/)
  );
};

export const isMembershipDashboard = (check) => {
  if (typeof window !== "undefined" && !check) {
    check = window.location.hash
      ? window.location.hash.replace(/^#/g, "")
      : window.location.pathname;
  }

  return (
    check.match(/.*\/membership-content/) ||
    check.match(/.*\/membership-content\/.*/)
  );
};

export const isCohortDashboard = (check) => {
  if (typeof window !== "undefined" && !check) {
    check = window.location.hash
      ? window.location.hash.replace(/^#/g, "")
      : window.location.pathname;
  }

  return (
    check.match(/.*\/cohort-content/) || check.match(/.*\/cohort-content\/.*/)
  );
};

export const isAdminPage = (check) => {
  if (typeof window !== "undefined" && !check) {
    check = window.location.hash
      ? window.location.hash.replace(/^#/g, "")
      : window.location.pathname;
  }

  return check.substring(0, 6) === "/admin";
};

export const getFundraisingSubTypePath = (subType) => {
  switch (subType) {
    case "FUNDRAISING_QURBAN":
      return "qurban";
    case "FUNDRAISING_WAQAF":
      return "wakaf";
    default:
      return "galang-dana";
  }
};

export const getPublicFundaisingV2URL = (username, link, subType) => {
  return process.env.NODE_ENV === "development"
    ? `/${username}/${getFundraisingSubTypePath(subType)}/v2/${link}`
    : `/${getFundraisingSubTypePath(subType)}/v2/${link}`;
};

export const getDonateNowURL = (username) => {
  return process.env.NODE_ENV === "development"
    ? `/${username}/donate-now`
    : `/donate-now`;
};

export const getLinkTreePathMayarURL = (url = "") => {
  let path = url;
  const reHostURL = /(((https)||(http))?:\/\/(?:[a-zA-Z0-9_]+[^\/]+)\/)/i;

  if (reHostURL.test(url)) {
    path = `/${url?.split("https://")[1]?.split("/")?.splice(1)?.join("/")}`;
  }

  return path;
};

export const handleResizeImageUrl = (url, size) => {
  // assume that the image url is always https://media.mayar.id/images/xxx for production and https://media.mayar.club/images/xxx for development
  // or https://media.mayar.club/images/resized/1000/39b23125-bc7b-4287-812b-d7c56cee5a12.jpeg

  if (!url) return null;
  else if (url && !size) return url;

  const urlArray = url.split("/");

  const first = urlArray.slice(0, 4).join("/");
  const last = urlArray.pop();
  return `${first}/resized/${size}/${last}`;
};

export const getPathBackPaymentChannel = (paymentLink, pltId) => {
  let pathname = `/pl/${paymentLink?.link}`;

  switch (paymentLink?.type) {
    case "physical_product":
      pathname = `/checkout/${paymentLink?.link}`;
      break;
    case "invoice":
      pathname = `/plt/${pltId}`;
      break;
    case "fundraising":
      pathname = `/donate/${paymentLink?.link}`;
      break;
    default:
      pathname = `/pl/${paymentLink?.link}`;
      break;
  }

  return pathname;
};

export const getYoutubeId = (url) => {
  const regExp =
    /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
  const match = url.match(regExp);

  return match && match[2].length === 11 ? match[2] : null;
};

/* Portal link path for product with content */
export const portalProductContentPath = (type, id, pltId) => {
  if (type === "audiobook" || type === "ebook" || type === "podcast") {
    return `/portal/${type}-detail/${pltId}`;
  } else if (type === "course") {
    return `/portal/course-content/${pltId}`;
  } else if (type === "digital_product") {
    return `/portal/digital-product-detail/${pltId}`;
  } else if (type === "cohort_based") {
    return `/portal/cohort-content/${id}`;
  }

  return null;
};

export const portalProductDetail = (type, bhId) => {
  if (productWithContentViewer.has(type)) {
    return `/portal/product-detail/${bhId}`;
  } else if (type === "saas") {
    return `/portal/app-detail/${bhId}`;
  } else if (type === "webinar" || type === "event") {
    return `/portal/${type}-detail/${bhId}`;
  } else if (type === "physical_product") {
    return `/portal/order-detail/${bhId}`;
  } else if (type === "payment_request") {
    return `/portal/payment-request-detail/${bhId}`;
  } else if (type === "generic_link") {
    return `/portal/payment-link-detail/${bhId}`;
  } else if (
    type === "coaching" ||
    type === "fundraising" ||
    type === "bundling"
  ) {
    return `/portal/${type}-detail/${bhId}`;
  }

  return null;
};

export const createWhatsappLink = (mobile, message) =>
  encodeURI(`https://wa.me/${mobile}?text=${message}`);

export const createQueryStringFromObject = (paramsObject) => {
  const queryParams = new URLSearchParams();

  for (const key in paramsObject) {
    if (paramsObject.hasOwnProperty(key)) {
      queryParams.append(key, paramsObject[key]);
    }
  }

  return queryParams.toString();
};

export const getCatalogPageByType = (type, username) => {
  const catalogPath = {
    generic_link: "plink",
    physical_product: "product-catalog",
    digital_product: "catalog",
    course: "course",
    bootcamp: "bootcamp",
    cohort_based: "bootcamp",
    webinar: "webinar",
    event: "event",
    coaching: "coaching",
    ebook: "ebook",
    podcast: "podcast",
    audiobook: "audiobook",
    membership: "membership",
    saas: "app",
  };

  return process.env.NODE_ENV === "development"
    ? `/${username}/${catalogPath[type] || type}`
    : `/${catalogPath[type] || type}`;
};

export const detailProductPath = {
  digital_product: "catalog",
  generic_link: "plink",
  physical_product: "product-catalog",
  webinar: "webinar",
  event: "event",
  coaching: "coaching",
  course: "course",
  cohort_based: "bootcamp",
  membership: "membership",
  audiobook: "audiobook",
  ebook: "ebook",
  podcast: "podcast",
  bundling: "bundling",
  saas: "app`",
};
