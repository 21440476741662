export const IMAGE_SMALL_SIZE = 850;
export const IMAGE_MEDIUM_SIZE = 1100;
export const IMAGE_LARGE_SIZE = 1500;

export const APP_TITLE_LIST: { [key: string]: string } = {
  "/:product/:slug": "${productName} - ${name}",
  "/app": "${name} - ${t('saas.title')}",
  "/audiobook": "${name} - ${t('audiobook.title')}",
  "/bootcamp": "${name} - ${t('cohort.title')}",
  "/bundling": "${name} - ${t('bundling.title')}",
  "/catalog": "${name} - ${t('digitalProduct.title')}",
  "/coaching": "${name} - ${t('coaching.title')}",
  "/course": "${name} - ${t('course.title')}",
  "/ebook": "${name} - ${t('ebook.title')}",
  "/event": "${name} - ${t('event.title')}",
  "/galang-dana": "${name} - ${t('fundraising.title')}",
  "/galang-dana/:slug": "${productName} - ${name}",
  "/membership": "${name} - ${t('membership.title')}",
  "/pay-membership": "${t('membership.payMembership')} - ${name}",
  "/plink": "${name} - ${t('paymentLink.title')}",
  "/podcast": "${name} - ${t('podcast.title')}",
  "/product-catalog": "${name} - ${t('physicalProduct.title')}",
  "/webinar": "${name} - ${t('webinar.title')}",
  "/lp/:slug": "${productName} - ${name}",
  "/landing/:slug": "${productName} - ${name}",
};
