import { IPaymentLinkTransaction } from "@/composables/payment-link-transaction.type";
import TagManager from "react-gtm-module";

type PurchaseType = {
  paymentLinkTransaction: Partial<IPaymentLinkTransaction>;
  paymentMethod: string;
};

export const gtmInitilize = (gtmId = "") => {
  TagManager.initialize({ gtmId, dataLayerName: "dataLayerGlobal" });
};

export const gtmPushClick = ({ featureName = "", customerData = {} }) => {
  TagManager.dataLayer({
    dataLayerName: "dataLayerGlobal",
    dataLayer: {
      event: "click",
      featureName,
      pageLocation: window.location.pathname,
      customerData,
    },
  });
};

export const gtmPageView = ({ merchantName = "" }) => {
  TagManager.dataLayer({
    dataLayerName: "dataLayerGlobal",
    dataLayer: {
      event: "pageView",
      pageTitle: window.document.title || merchantName,
      pageLocation: window.location.pathname,
    },
  });
};

export const gtmPurchase = ({
  paymentLinkTransaction,
  paymentMethod,
}: PurchaseType) => {
  TagManager.dataLayer({
    dataLayerName: "dataLayerGlobal",
    dataLayer: {
      event: "purchase",
      pageTitle: window.document.title,
      pageLocation: window.location.pathname,
      // checkoutStatus: paymentLinkTransaction?.status,
      checkoutName: paymentLinkTransaction?.paymentLink?.name,
      checkoutTotal: paymentLinkTransaction?.amount,
      currency: "IDR",
      // productNames: productNames,
      paymentMethod,
    },
  });
};
