import CryptoJS from "crypto-js";

export const encrypt = (dataToEncrypt) => {
  return CryptoJS.AES.encrypt(
    JSON.stringify(dataToEncrypt),
    process.env.NEXT_PUBLIC_MAYAR_SECRET_KEY,
    {
      keySize: 128 / 8,
      iv: process.env.NEXT_PUBLIC_MAYAR_SECRET_KEY,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }
  ).toString();
};

export const decrypt = (dataToDecrypt) => {
  return JSON.parse(
    CryptoJS.enc.Utf8.stringify(
      CryptoJS.AES.decrypt(
        dataToDecrypt,
        process.env.NEXT_PUBLIC_MAYAR_SECRET_KEY,
        {
          keySize: 128 / 8,
          iv: process.env.NEXT_PUBLIC_MAYAR_SECRET_KEY,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7,
        }
      )
    )
  );
};
