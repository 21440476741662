// import DOMPurify from 'dompurify';
import DOMPurify from 'isomorphic-dompurify';

export const sanitizeDOM = (dirty) => {
  return DOMPurify.sanitize(dirty, {
    ADD_TAGS: ['iframe'],
    ALLOWED_ATTR: ['href', 'src', 'style', 'allow', 'allowfullscreen', 'frameborder', 'scrolling', 'target', 'width', 'height'],
  });
};

export const sanitizeDOMIgnoreMedia = (dirty) => {
  return DOMPurify.sanitize(dirty, {
    FORBID_TAGS: ['iframe', 'img'],
    ALLOWED_ATTR: ['href', 'src', 'style', 'allow', 'allowfullscreen', 'frameborder', 'scrolling', 'target', 'class'],
  });
};

export const sanitizeStrict = (dirty) => {
  return DOMPurify.sanitize(dirty);
};

export const sanitizeWhatsAppLink = (whatsappLink) => {
  const match = whatsappLink.match(/(https:\/\/api.whatsapp.com\/send\?phone=)(\+\d+)/);
  if (match) {
    const sanitizedLink = match[1] + match[2].replace('+', ''); // Remove the plus sign
    return sanitizedLink;
  }
  return whatsappLink; // Return the original link if it doesn't match the pattern
}
