/* 
  - get the cheapest amount from membership tiers
  - get membership tier which has the cheapest amount
*/
export const findCheapestTierPeriodPrice = (membershipTiers = [], isAmountOnly = true) => {
  let lowestAmountPeriod = 0;
  let lowestTierObject = {}
  let lowestTierPeriodObject = {}

  if (membershipTiers.length > 0) { 
    // find the cheapest price for default selected tier
    for (const membershipTier of membershipTiers) {
      if (membershipTier.status === "ACTIVE" && membershipTier?.membershipTierPeriod?.length > 0) {
        const activePeriods = membershipTier?.membershipTierPeriod?.filter(period => period.status === "ACTIVE") || []
        if (activePeriods.length < 1) continue;

        const lowestActiveObject = activePeriods.reduce((min, currentObject) => {
          return currentObject.amount < min.amount ? currentObject : min;
        });

        if ((lowestAmountPeriod && lowestAmountPeriod > lowestActiveObject.amount) || !lowestAmountPeriod) {
          lowestAmountPeriod = lowestActiveObject.amount
          lowestTierObject = membershipTier
          lowestTierPeriodObject = lowestActiveObject
        }
      }
    }
  }

  if (isAmountOnly) {
    return {
      lowestAmountPeriod
    }
  }

  return { lowestAmountPeriod, lowestTierObject, lowestTierPeriodObject }
}
