/* eslint-disable no-undef */
import { generateRandomString } from '.';

export const getFileFromURL = (url, fileName = 'filename') =>
  fetch(`${url}?noCache=${generateRandomString(5)}`, { mode: 'cors' }).then(async (response) => {
    const blob = await response.blob();

    const file = new File([blob], fileName, { type: blob.type });
    return file;
  });
