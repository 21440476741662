import { validator } from "./validator";

const basePath = null;

export const donationCategory = [
  "Bantuan Pendidikan",
  "Bencana Alam",
  "Difabel",
  "Infrastruktur Umum",
  "Karya Kreatif / Modal Usaha",
  "Kegiatan Sosial",
  "Kemanusiaan",
  "Lingkungan",
  "Menolong Hewan",
  "Panti Asuhan",
  "Rumah Ibadah",
  "Lainnya",
];

export const courseModuleTypes = {
  text: {
    type: "text",
    text: "Bacaan",
    icon: "file-text",
    iconify: "mdi-light:note-text",
    placeholder: "This just some placeholder if you want to add some later.",
  },
  embed: {
    type: "embed",
    text: "Video",
    icon: "youtube",
    iconify: "mynaui:brand-youtube",
    placeholder: "This just some placeholder if you want to add some later.",
  },
  iframe: {
    type: "iframe",
    text: "Embed Iframe",
    icon: "square",
    iconify: "humbleicons:square",
    placeholder: "This just some placeholder if you want to add some later.",
  },
  video_drm: {
    type: "video_drm",
    text: "Video DRM",
    icon: "video",
    iconify: "mynaui:video",
    placeholder: "This just some placeholder if you want to add some later.",
  },
  quiz: {
    type: "quiz",
    text: "Kuis",
    icon: "help-circle",
    iconify: "clarity:help-line",
    placeholder: "This just some placeholder if you want to add some later.",
  },
  coaching: {
    type: "coaching",
    text: "Coaching",
    icon: "watch",
    iconify: "majesticons:watch-line",
    placeholder: "This just some placeholder if you want to add some later.",
  },
  files: {
    type: "files",
    text: "Files",
    icon: "paperclip",
    iconify: "ph:paperclip",
    placeholder: "This just some placeholder if you want to add some later.",
  },
  audio: {
    type: "audio",
    text: "Audio",
    icon: "headphones",
    iconify: "ph:headphones",
    placeholder: "This just some placeholder if you want to add some later.",
  },
};

export const defaultChannelConfig = [
  {
    name: "QRIS",
    status: true,
    img: `${basePath ? basePath : ""}/qris-gpn.svg`,
    type: "qris",
    code: "QRIS",
  },
  {
    name: "Bank Mandiri",
    status: true,
    img: `${basePath ? basePath : ""}/mandiri.svg`,
    type: "va",
    code: "MANDIRI",
  },
  {
    name: "Bank BNI",
    status: true,
    img: `${basePath ? basePath : ""}/bni.svg`,
    type: "va",
    code: "BNI",
  },
  {
    name: "Bank BRI",
    status: true,
    img: `${basePath ? basePath : ""}/bri.svg`,
    type: "va",
    code: "BRI",
  },
  {
    name: "Bank BSI",
    status: true,
    img: `${basePath ? basePath : ""}/bsi.svg`,
    type: "va",
    code: "BSI",
  },
  {
    name: "Bank CIMB Niaga",
    status: true,
    img: `${basePath ? basePath : ""}/cimb.svg`,
    type: "va",
    code: "CIMB",
  },
  {
    name: "Bank BJB",
    status: true,
    img: `${basePath ? basePath : ""}/bjb.svg`,
    type: "va",
    code: "BJB",
  },
  {
    name: "Bank Permata",
    status: true,
    img: `${basePath ? basePath : ""}/permata.svg`,
    type: "va",
    code: "PERMATA",
  },
  {
    name: "Flip",
    status: true,
    img: `${basePath ? basePath : ""}/flip.svg`,
    type: "va",
    code: "FLIP",
  },
  {
    name: "Bank Lainnya",
    status: true,
    img: `${basePath ? basePath : ""}/bank-lain.svg`,
    type: "va",
    code: "OTHER",
  },
  {
    name: "OVO",
    status: true,
    img: `${basePath ? basePath : ""}/ovo.svg`,
    type: "ewallet",
    code: "OVO",
  },
  {
    name: "DANA",
    status: true,
    img: `${basePath ? basePath : ""}/dana.svg`,
    type: "ewallet",
    code: "DANA",
  },
  {
    name: "LinkAja",
    status: true,
    img: `${basePath ? basePath : ""}/LinkAja.svg`,
    type: "ewallet",
    code: "LINKAJA",
  },
  {
    name: "Jenius",
    status: true,
    img: `${basePath ? basePath : ""}/Jenius.svg`,
    type: "ewallet",
    code: "JENIUSPAY",
  },
  {
    name: "ShopeePay",
    status: true,
    img: `${basePath ? basePath : ""}/shopeepay.svg`,
    type: "ewallet",
    code: "SHOPEEPAY",
  },
  {
    name: "Kartu Kredit Debit",
    status: false,
    img: `${basePath ? basePath : ""}/visa-master.svg`,
    type: "card",
  },
  {
    name: "Alfamart",
    status: true,
    img: `${basePath ? basePath : ""}/alfa-logo.svg`,
    type: "retail",
    code: "ALFAMART",
  },
  {
    name: "Indomaret",
    status: true,
    img: `${basePath ? basePath : ""}/indo-logo.svg`,
    type: "retail",
    code: "INDOMARET",
  },
  {
    name: "Akulaku",
    status: false,
    img: `${basePath ? basePath : ""}/akulaku-logo.svg`,
    type: "paylater",
    code: "AKULAKU",
  },
];

export const defaultChannelConfigSubs = [
  {
    name: "QRIS",
    status: false,
    img: `${basePath ? basePath : ""}/qris-gpn.svg`,
    type: "qris",
    code: "QRIS",
  },
  {
    name: "Bank Mandiri",
    status: true,
    img: `${basePath ? basePath : ""}/mandiri.svg`,
    type: "va",
    code: "MANDIRI",
  },
  {
    name: "Bank BNI",
    status: true,
    img: `${basePath ? basePath : ""}/bni.svg`,
    type: "va",
    code: "BNI",
  },
  {
    name: "Bank BRI",
    status: true,
    img: `${basePath ? basePath : ""}/bri.svg`,
    type: "va",
    code: "BRI",
  },
  {
    name: "Bank BSI",
    status: false,
    img: `${basePath ? basePath : ""}/bsi.svg`,
    type: "va",
    code: "BSI",
  },
  {
    name: "Bank BJB",
    status: false,
    img: `${basePath ? basePath : ""}/bjb.svg`,
    type: "va",
    code: "BJB",
  },
  {
    name: "Bank CIMB Niaga",
    status: true,
    img: `${basePath ? basePath : ""}/cimb.svg`,
    type: "va",
    code: "CIMB",
  },
  {
    name: "Bank Permata",
    status: true,
    img: `${basePath ? basePath : ""}/permata.svg`,
    type: "va",
    code: "PERMATA",
  },
  {
    name: "Flip",
    status: true,
    img: `${basePath ? basePath : ""}/flip.svg`,
    type: "va",
    code: "FLIP",
  },
  {
    name: "Bank Lainnya",
    status: false,
    img: `${basePath ? basePath : ""}/bank-lain.svg`,
    type: "va",
    code: "BNI",
  },
  {
    name: "OVO",
    status: true,
    img: `${basePath ? basePath : ""}/ovo.svg`,
    type: "ewallet",
    code: "OVO",
  },
  {
    name: "DANA",
    status: true,
    img: `${basePath ? basePath : ""}/dana.svg`,
    type: "ewallet",
    code: "DANA",
  },
  {
    name: "LinkAja",
    status: false,
    img: `${basePath ? basePath : ""}/LinkAja.svg`,
    type: "ewallet",
    code: "LINKAJA",
  },
  {
    name: "ShopeePay",
    status: false,
    img: `${basePath ? basePath : ""}/shopeepay.svg`,
    type: "ewallet",
    code: "SHOPEEPAY",
  },
  {
    name: "Kartu Kredit Debit",
    status: false,
    img: `${basePath ? basePath : ""}/visa-master.svg`,
    type: "card",
  },
  {
    name: "Alfamart",
    status: true,
    img: `${basePath ? basePath : ""}/alfa-logo.svg`,
    type: "retail",
    code: "ALFAMART",
  },
  {
    name: "Indomaret",
    status: true,
    img: `${basePath ? basePath : ""}/indo-logo.svg`,
    type: "retail",
    code: "INDOMARET",
  },
  // {
  //   name: 'Akulaku',
  //   status: true,
  //   img: '/akulaku-logo.svg',
  //   type: 'paylater',
  //   code: 'AKULAKU',
  // },
];

// add payment code from config to this list to disable specific payment channel
export const temporaryPaymentDisabled = ["OVO", "AKULAKU"];

export const productOptionsHeader = [
  {
    value: "generic_link",
    label: "Link Pembayaran",
    isDisabled: true,
    color: "gray",
    tailwindcolor: "bg-gray-200",
  },
  {
    value: "physical_product",
    label: "Produk Fisik",
    isDisabled: true,
    color: "teal",
    tailwindcolor: "bg-[#20b7e2]",
  },
  {
    value: "digital_product",
    label: "Produk Digital",
    isDisabled: true,
    color: "#20b7e2",
    tailwindcolor: "bg-[#20b7e2]",
  },
  {
    value: "course",
    label: "Kelas Online",
    isDisabled: true,
    color: "#d45428",
    tailwindcolor: "bg-[#d45428]",
  },
  {
    value: "cohort_based",
    label: "Kelas Cohort / Bootcamp",
    isDisabled: true,
    color: "indigo",
    tailwindcolor: "bg-[#6096a6]",
  },
  {
    value: "webinar",
    label: "Webinar",
    isDisabled: true,
    color: "#19447c",
    tailwindcolor: "bg-[#19447c]",
  },
  {
    value: "event",
    label: "Event",
    isDisabled: true,
    color: "orange",
    tailwindcolor: "bg-[#6096a6]",
  },
  {
    value: "coaching",
    label: "Coaching",
    isDisabled: true,
    color: "#6096a6",
    tailwindcolor: "bg-[#6096a6]",
  },
  {
    value: "ebook",
    label: "E-Book",
    isDisabled: true,
    color: "green",
    tailwindcolor: "bg-green-500",
  },
  {
    value: "podcast",
    label: "Podcast",
    isDisabled: true,
    color: "#fec20c",
    tailwindcolor: "bg-[#fec20c]",
  },
  {
    value: "audiobook",
    label: "Audio Book",
    isDisabled: true,
    color: "#bb437e",
    tailwindcolor: "bg-[#bb437e]",
  },
  {
    value: "bundling",
    label: "Bundling",
    isDisabled: true,
    color: "#649724",
    tailwindcolor: "bg-[#649724]",
  },
  {
    value: "fundraising",
    label: "Galang Dana",
    isDisabled: true,
    color: "#a4c42d",
    tailwindcolor: "bg-[#a4c42d]",
  },
  {
    value: "membership",
    label: "Membership",
    isDisabled: true,
    color: "#37c871",
    tailwindcolor: "bg-[#37c871]",
  },
  {
    value: "saas",
    label: "Software & SaaS",
    isDisabled: true,
    color: "#706233",
    tailwindcolor: "bg-[#706233]",
  },
  {
    value: "writing",
    label: "Tulisan / Writing",
    isDisabled: true,
    color: "#176533",
    tailwindcolor: "bg-[#176533]",
  },
];

export const productRoute = {
  saas: "app",
};

export const linksType = [
  { label: "Katalog", type: "CATALOG", icon: "folderPlus" },
  { label: "Produk", type: "PRODUCT", icon: "tag" },
  { label: "Teks", type: "TEXT", icon: "type" },
  {
    label: "URL / Link Website",
    type: "URL",
    placeholder: "Url / Link",
    pattern: validator.url,
    icon: "link",
  },
  { label: "Subscription", type: "SUBSCRIPTION", icon: "repeat" },
  { label: "Support", type: "SUPPORT", icon: "smile" },
  { label: "Kode Promo", type: "COUPON", icon: "percent" },
  {
    label: "Marketplace",
    type: "MARKETPLACE",
    placeholder: "Url / Link Marketplace",
    pattern: validator.url,
    icon: ["fas", "store"],
    vendor: "fa",
  },
  {
    label: "HTML Embed",
    type: "HTML",
    placeholder: "Kode HTML",
    icon: "code",
  },
  {
    label: "Iframe",
    type: "IFRAME",
    placeholder: "https://www.youtube.com/watch?v=XXXXXXXXXX",
    pattern: validator.url,
    icon: "tv",
  },
  {
    label: "Youtube",
    type: "YOUTUBE",
    placeholder: "https://www.youtube.com/watch?v=XXXXXXXXXX",
    pattern: validator.pattern(
      /(https:\/\/(www\.)?((youtube\.com)||(youtu\.be))?\/(embed\/)?)((?:[-a-zA-Z0-9()@:%_\+.~#?&//=][-a-zA-Z0-9()@:%_\+.~#?&//=]*))/i
    ),
    icon: "youtube",
  },
  {
    label: "Tiktok",
    type: "TIKTOK",
    placeholder: "https://www.tiktok.com/@username/video/XXXXXXXXXX",
    pattern: validator.pattern(
      /(https:\/\/(www\.)?(tiktok\.com)?(((?:\/@[-a-zA-Z0-9()@:%_\+.~#?&//=][-a-zA-Z0-9()@:%_\+.~#?&//=]*))||\/)(video\/)?)((?:[-a-zA-Z0-9()@:%_\+.~#?&//=][-a-zA-Z0-9()@:%_\+.~#?&//=]*))/i
    ),
    icon: ["fab", "tiktok"],
    vendor: "fa",
  },
  {
    label: "Tweet",
    type: "TWITTER",
    placeholder: "https://twitter.com/username/status/tweetid",
    pattern: validator.pattern(
      /(https:\/\/(www\.)?(twitter\.com)\/(?:[-a-zA-Z0-9()@:%_\+.~#?&//=][-a-zA-Z0-9()@:%_\+.~#?&//=]*)\/(status\/))((?:[-a-zA-Z0-9()@:%_\+.~#?&//=][-a-zA-Z0-9()@:%_\+.~#?&//=]*))/i
    ),
    icon: "twitter",
  },
  {
    label: "Spotify",
    type: "SPOTIFY",
    placeholder: "https://open.spotify.com/type/xxxxxxxx",
    pattern: validator.pattern(
      /(https:\/\/(open\.)?(spotify\.com)?(\/embed\/? || \/)(?:[-a-zA-Z0-9()@:%_\+.~#?&//=][-a-zA-Z0-9()@:%_\+.~#?&//=]*)\/)((?:[-a-zA-Z0-9()@:%_\+.~#?&//=][-a-zA-Z0-9()@:%_\+.~#?&//=]*))/i
    ),
    icon: ["fab", "spotify"],
    vendor: "fa",
  },
  {
    label: "Banner",
    type: "BANNER",
    placeholder: "Url / Link Banner",
    pattern: validator.url,
    icon: "image",
  },
];

export const productWithContentViewer = new Set([
  "course",
  "cohort_based",
  "digital_product",
  "audiobook",
  "ebook",
  "podcast",
]);

export const productTypeForNavigation = {
  course: "kelas",
  bootcamp: "bootcamp",
  membership: "membership",
  digital_product: "produk digital",
  event: "event",
};

export const monthsCollection = [
  "january",
  "february",
  "march",
  "april",
  "may",
  "june",
  "july",
  "august",
  "september",
  "october",
  "november",
  "december",
];

export const defaultImage = {
  url: "/digital-product-placeholder.webp",
};
